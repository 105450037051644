var gallery = null;

$(document).ready(function(){


	gallery  = new scroller('.custom-slider');

	//console.log(gallery);

});



/**
 * @param selector
 * @returns
 */
function scroller(selector)
{

	this.selector = selector;

	this.totalSlides = 1;

	this.currentSlide = 3;
	this.visibleSlides = 7;

	this.obj = null;

	this.navItemHeight = 100;

	this.cycle = false;

	this.animationDelay = 300;

	this.isAnimating = 0;

	this.init = function(selector)
	{
		this.obj = $(this.selector);

		if(this.obj == null)
		{

			alert('no id');
			return;
		}


		this.calculateTotalSlides();

		this.calculateNavItemHeight();

		this.setNavItemHeight();

		this.setAttributes();

		this.bindEvents();


		this.setIntialActiveItem();

		this.setPaddingsDown();

	}

	this.setActiveSlide = function () {


	}

	this.setAttributes = function () {

		this.obj.find('.custom-nav-list .custom-nav-item').each(function(i){

			$(this).attr("data-slide", i);
		});
	}
	this.setIntialActiveItem = function()
	{
		this.clearActiveItem();
		this.setActiveItem(this.currentSlide);
	}

	this.calculateTotalSlides = function ()
	{
		this.totalSlides = this.obj.find('.custom-nav-list .custom-nav-item').length;
	}

	this.calculateNavItemHeight = function()
	{
		this.navItemHeight = 100/this.visibleSlides+"%";
	}

	this.setNavItemHeight = function()
	{
		this.obj.find('.custom-nav-list .custom-nav-item').css({height: this.navItemHeight});

	}

	this.setActiveItem = function(itemId)
	{
		this.obj.find('.custom-nav-list .custom-nav-item').eq(itemId).addClass('active');
		var slideId = this.obj.find('.custom-nav-list .custom-nav-item').eq(itemId).attr("data-slide");

		this.obj.find('.custom-slides .custom-slide').css('display', 'none');
		this.obj.find('.custom-slides .custom-slide').eq(slideId).fadeIn();

	}

	this.clearActiveItem = function()
	{
		this.obj.find('.custom-nav-list .custom-nav-item').removeClass('active');

	}

	this.bindEvents = function()
	{
		$(document).on('click','.custom-nav-list .custom-nav-item',{parentClass:this,fromControl:0},this.clickNavItem);
		$(document).on('click','.custom-control .prev',{parentClass:this,fromControl:-1},this.clickNavItem);
		$(document).on('click','.custom-control .next',{parentClass:this,fromControl:1},this.clickNavItem);
	}



	this.clickNavItem = function(carouselEvent)
	{
		if(!carouselEvent.data.parentClass.isAnimating)
		{
			carouselEvent.data.parentClass.isAnimating = 1;

			var nextItem = 0;
			if(carouselEvent.data.fromControl == 0)
			{
				nextItem = $(carouselEvent.target).index();
			}
			else
			{
				nextItem = carouselEvent.data.parentClass.currentSlide + carouselEvent.data.fromControl;
			}


			carouselEvent.data.parentClass.clearActiveItem();
			carouselEvent.data.parentClass.setActiveItem(nextItem);

			if(carouselEvent.data.parentClass.currentSlide < nextItem)
			{
				var slidesToMove = nextItem - carouselEvent.data.parentClass.currentSlide;

				carouselEvent.data.parentClass.removeFirstElement(carouselEvent,slidesToMove);
			}



			if(carouselEvent.data.parentClass.currentSlide > nextItem)
			{
				var slidesToMove =  carouselEvent.data.parentClass.currentSlide - nextItem;

				carouselEvent.data.parentClass.removeLastElement(carouselEvent,slidesToMove);
			}

		}
	}



	this.setPaddingsUp = function()
	{
		this.obj.find('.custom-nav-list .custom-nav-item').eq(0).removeClass('offset30').addClass('offset40');
		this.obj.find('.custom-nav-list .custom-nav-item').eq(1).removeClass('offset20').addClass('offset30');
		this.obj.find('.custom-nav-list .custom-nav-item').eq(2).removeClass('offset10').addClass('offset20');
		this.obj.find('.custom-nav-list .custom-nav-item').eq(3).removeClass('offset0').addClass('offset10');;
		this.obj.find('.custom-nav-list .custom-nav-item').eq(4).removeClass('offset10').addClass('offset0');;
		this.obj.find('.custom-nav-list .custom-nav-item').eq(5).removeClass('offset20').addClass('offset10');;
		this.obj.find('.custom-nav-list .custom-nav-item').eq(6).removeClass('offset30').addClass('offset20');;
		this.obj.find('.custom-nav-list .custom-nav-item').eq(7).removeClass('offset40').addClass('offset30');;

		for(i=8; i<this.totalSlides; i++)
		{
			this.obj.find('.custom-nav-list .custom-nav-item').eq(i).removeClass('offset30').addClass('offset40');;
		}
	}


	this.setPaddingsDown = function()
	{
		this.obj.find('.custom-nav-list .custom-nav-item').eq(0).removeClass('offset40').addClass('offset30');
		this.obj.find('.custom-nav-list .custom-nav-item').eq(1).removeClass('offset30').addClass('offset20');
		this.obj.find('.custom-nav-list .custom-nav-item').eq(2).removeClass('offset20').addClass('offset10');
		this.obj.find('.custom-nav-list .custom-nav-item').eq(3).removeClass('offset10').addClass('offset0');;
		this.obj.find('.custom-nav-list .custom-nav-item').eq(4).removeClass('offset0').addClass('offset10');;
		this.obj.find('.custom-nav-list .custom-nav-item').eq(5).removeClass('offset10').addClass('offset20');;
		this.obj.find('.custom-nav-list .custom-nav-item').eq(6).removeClass('offset20').addClass('offset30');;
		this.obj.find('.custom-nav-list .custom-nav-item').eq(7).removeClass('offset30').addClass('offset40');;
		this.obj.find('.custom-nav-list .custom-nav-item').eq(8).addClass('offset40');;
	}


	this.removeFirstElement = function(carouselEvent,leftToRemove)
	{

		var eventdata = carouselEvent.data;

		leftToRemove -- ;


		eventdata.parentClass.isAnimating = 1;

		var first = eventdata.parentClass.obj.find('.custom-nav-list .custom-nav-item').first().clone();
		eventdata.parentClass.obj.find('.custom-nav-list').append(first);

		eventdata.parentClass.obj.find('.custom-nav-list .custom-nav-item').first().animate({marginTop: "-"+first.height()},eventdata.parentClass.animationDelay,'linear',function(){


			eventdata.parentClass.obj.find('.custom-nav-list .custom-nav-item').first().remove();
			
			if(leftToRemove > 0)
			{
				eventdata.parentClass.removeFirstElement({data: eventdata}, leftToRemove);
			}
			else
			{
				eventdata.parentClass.isAnimating = 0;
			}

		});


		this.setPaddingsUp();


	}



	this.removeLastElement = function(carouselEvent,leftToRemove)
	{
		var eventData = carouselEvent.data;

		leftToRemove -- ;

		eventData.parentClass.isAnimating = 1;

		var last = eventData.parentClass.obj.find('.custom-nav-list .custom-nav-item').last().clone();
		var lastHeight= eventData.parentClass.obj.find('.custom-nav-list .custom-nav-item').last().height();
		last.css({marginTop: "-"+lastHeight+"px"});

		eventData.parentClass.obj.find('.custom-nav-list').prepend(last);


		eventData.parentClass.obj.find('.custom-nav-list .custom-nav-item').first().animate({marginTop: 0},eventData.parentClass.animationDelay,'linear',function(){

			eventData.parentClass.obj.find('.custom-nav-list .custom-nav-item').last().remove();

			if(leftToRemove > 0)
			{
				eventData.parentClass.removeLastElement({data: eventData},leftToRemove);
			}
			else
			{
				eventData.parentClass.isAnimating = 0;
			}
		});
		this.setPaddingsDown();
	}

	this.init();

	return this;
}


